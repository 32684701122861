// src/App.js
import React from 'react';
import PlayerTable from './PlayerTable';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <h1>Casino Voice Table</h1>
        <PlayerTable />
      </header>
    </div>
  );
}

export default App;
