import React, { useState, useEffect, useRef } from 'react';
import { ZegoExpressEngine } from 'zego-express-engine-webrtc';

const App = ({ stream, room, userId }) => {
    const [userID, setUserID] = useState(userId);
    const [roomID, setRoomID] = useState(room);
    const [streamID, setStreamID] = useState(stream);
    const [localStream, setLocalStream] = useState(null);
    const [remoteStreams, setRemoteStreams] = useState({});
    const [isMuted, setIsMuted] = useState(false);
    const [userCount, setUserCount] = useState(0);
    const [mediaError, setMediaError] = useState('');
    const zgRef = useRef(null);

    useEffect(() => {
        const initZego = async () => {
            try {
                const token = await handleJoinRoom();
                const appID = 1943790779;
                const server = '89495af8dc85d7ca7c146d5853847d39';
                zgRef.current = new ZegoExpressEngine(appID, server);
    
                await zgRef.current.loginRoom(roomID, token, { userID: userID, userName: 'User' }, { userUpdate: true });
    
                const localStream = await zgRef.current.createZegoStream({ camera: { audio: true, video: false } });
                setLocalStream(localStream);
                await zgRef.current.startPublishingStream(streamID, localStream);
    
                zgRef.current.on('roomStateUpdate', (roomID, state, errorCode) => {
                    console.log(`Room State Update: ${roomID}, ${state}, ${errorCode}`);
                    if (state === 'DISCONNECTED') {
                        // Handle reconnection logic here
                        console.error('Disconnected from room, trying to reconnect...');
                        // Add reconnection attempts logic here
                    }
                });
    
                zgRef.current.on('roomStreamUpdate', async (roomID, updateType, streamList) => {
                    if (updateType === 'ADD') {
                        for (const stream of streamList) {
                            const streamID = stream.streamID;
                            const remoteStream = await zgRef.current.startPlayingStream(streamID);
                            setRemoteStreams(prev => ({ ...prev, [streamID]: remoteStream }));
                        }
                    } else if (updateType === 'DELETE') {
                        for (const stream of streamList) {
                            const streamID = stream.streamID;
                            zgRef.current.stopPlayingStream(streamID);
                            setRemoteStreams(prev => {
                                const newStreams = { ...prev };
                                delete newStreams[streamID];
                                return newStreams;
                            });
                        }
                    }
                });
    
                zgRef.current.on('error', (err) => {
                    console.error('Zego Engine Error:', err);
                    if (err.code === 3002) {
                        setMediaError('Failed to connect to the server. Please check your network connection.');
                    }
                });
    
            } catch (error) {
                console.error('Error initializing Zego engine:', error);
                setMediaError('Failed to initialize Zego engine. Please try again.');
            }
        };
    
        initZego();
    }, []);
    

    const handleJoinRoom = async () => {
        try {
            const response = await fetch('http://localhost:3500/auth/zego-auth-token', {
                method: 'POST',
                headers: { 
                    'Content-Type': 'application/json',
                    'Authorization': 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6NSwiYWRkcmVzcyI6IjB4Nzc4NjU0QjdDRTdhRTYxQ2U0ODkzMTZFMjRkOTZjYTFFMEJiMGNhMiIsInJlZmVycmFsQ29kZSI6IjRQS3l6R21OIiwibm9uY2UiOiJhZGI2Y2VhOS0zYTA5LTQ0NzctOTIzYi00Mjc4ZjJjZWQ1MzEiLCJ3YWxsZXRUeXBlIjoiZXRoZXJldW0iLCJwcm9maWxlVXJsIjoiaHR0cDovL2xvY2FsaG9zdDozNTAwL3VwbG9hZHMvcHJvZmlsZXMvcHJvZmlsZS0xNzIzMjcyNzk1NTc0LTY0Mjc3OTc3MC5wbmciLCJ1c2VyTmFtZSI6ImhlcmUgcGFwdSIsImlhdCI6MTcyMzI3Mjc5NSwiZXhwIjoxNzIzMzU5MTk1fQ.Qha7Z6__PCXZCcZXvCL4305dSBKG8QUj-1VGz0WEZo8' // Replace with your actual token
                }
            });

            if (!response.ok) {
                throw new Error('Network response was not ok');
            }

            const data = await response.json();
            return data.data.token;
        } catch (error) {
            console.error('Error joining room:', error);
            setMediaError('Failed to join the room. Please check your network connection and try again.');
        }
    };

    const handleLeaveRoom = () => {
        if (localStream) {
            zgRef.current.stopPublishingStream(streamID);
            zgRef.current.destroyStream(localStream);
            setLocalStream(null);
        }
        zgRef.current.logoutRoom(roomID);
        zgRef.current.destroyEngine();
    };

    const handleToggleMute = () => {
        if (localStream) {
            const audioTrack = localStream.getAudioTracks()[0];
            if (audioTrack) {
                audioTrack.enabled = !isMuted;
                setIsMuted(!isMuted);
            }
        }
    };

    return (
        <div className="app-container">
            <div className="control-panel">
                <input
                    type="text"
                    placeholder="User ID"
                    value={userID}
                    onChange={(e) => setUserID(e.target.value)}
                />
                <input
                    type="text"
                    placeholder="Room ID"
                    value={roomID}
                    onChange={(e) => setRoomID(e.target.value)}
                />
                <button onClick={handleJoinRoom} className="btn">Join Room</button>
                <button onClick={handleLeaveRoom} className="btn">Leave Room</button>
                <button onClick={handleToggleMute} className="btn">
                    {isMuted ? 'Unmute' : 'Mute'}
                </button>
            </div>
            {mediaError && <div className="error-message">{mediaError}</div>}
            <div className="user-info">
                <h3>Number of people in room: {userCount}</h3>
            </div>
            <div className="stream-container">
                {Object.keys(remoteStreams).map(streamID => (
                    <div key={streamID} className="user-stream">
                        <audio
                            autoPlay
                            ref={audio => {
                                if (audio) {
                                    audio.srcObject = remoteStreams[streamID];
                                }
                            }}
                        />
                        <div className="user-voice-visualization">
                            {/* Add any voice visualization here */}
                            <p>Voice Visualization for {streamID}</p>
                        </div>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default App;
