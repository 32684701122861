import React, { useState, useEffect, useRef } from 'react';
import { createClient, createMicrophoneAudioTrack } from 'agora-rtc-sdk-ng';

const Room = ({ appId, token, roomId, uid }) => {
  const [joined, setJoined] = useState(false);
  const [localTrack, setLocalTrack] = useState(null);
  const [users, setUsers] = useState([]);
  const [speakingUser, setSpeakingUser] = useState(null);
  const clientRef = useRef(null);
  const remoteUsersRef = useRef({});

  useEffect(() => {
    const client = createClient({ mode: 'rtc', codec: 'vp8' });
    clientRef.current = client;

    const initAgora = async () => {
      try {
        await client.join(appId, roomId, token, uid);
        console.log('Successfully joined the room');

        const audioTrack = await createMicrophoneAudioTrack();
        setLocalTrack(audioTrack);
        await client.publish([audioTrack]);
        setJoined(true);
        console.log('Audio track published');

        // Event listener for new users
        client.on('user-published', async (user, mediaType) => {
          if (mediaType === 'audio') {
            await client.subscribe(user, mediaType);
            const remoteAudioTrack = user.audioTrack;
            remoteUsersRef.current[user.uid] = remoteAudioTrack;
            remoteAudioTrack.play();

            // Update user list
            setUsers((prevUsers) => [...prevUsers, { uid: user.uid, track: remoteAudioTrack }]);
          }
        });

        // Event listener for user leaving or stopping
        client.on('user-unpublished', (user) => {
          if (remoteUsersRef.current[user.uid]) {
            remoteUsersRef.current[user.uid].stop();
            delete remoteUsersRef.current[user.uid];
            setUsers((prevUsers) => prevUsers.filter((u) => u.uid !== user.uid));
          }
        });

        client.on('user-left', (user) => {
          if (remoteUsersRef.current[user.uid]) {
            remoteUsersRef.current[user.uid].stop();
            delete remoteUsersRef.current[user.uid];
            setUsers((prevUsers) => prevUsers.filter((u) => u.uid !== user.uid));
          }
        });

        // Event listener for audio volume
        client.on('volume-indication', (volumes) => {
          if (volumes.length > 0) {
            const maxVolumeUser = volumes.reduce((prev, current) => (prev.volume > current.volume ? prev : current));
            setSpeakingUser(maxVolumeUser.uid);
          }
        });
      } catch (error) {
        console.error('Error joining the room:', error);
      }
    };

    initAgora();

    return () => {
      const client = clientRef.current;
      if (client) {
        client.leave().then(() => console.log('Left the room'));
      }
      if (localTrack) {
        localTrack.close();
      }
    };
  }, [appId, roomId, token, uid]);

  return (
    <div>
      <h1>Room: {roomId}</h1>
      {joined ? (
        <div>
          <p>Joined the room and publishing audio.</p>
          <p>Number of users: {users.length}</p>
          <ul>
            {users.map((user) => (
              <li key={user.uid}>
                User ID: {user.uid} {speakingUser === user.uid && '(Speaking)'}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <p>Joining the room...</p>
      )}
    </div>
  );
};

export default Room;
